import { initSliderOnMobile } from './functions';
export function allSliders() {
  initSliderOnMobile('.leistungen__cards-list', {}, 'large', true);

  initSliderOnMobile('.facts-stories-4-cards__cards-list', {}, 'large', true);

  initSliderOnMobile('.usp-section__cards-list', {}, 'large', true);

  initSliderOnMobile('.trust-section__list', {}, 'large', true);

  initSliderOnMobile('.process-section__tab-buttons', { infinite: false }, 'large', true);
}
