import Foundation from './foundation';

export function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium',
  setArrows = false
) {
  if (!$(selector).length) return;

  $(selector).each(function (idx, item) {
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = $(item).hasClass('slick-initialized');
      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
          $(item).closest('section').removeClass('slick-initialized');
        }
      } else if (!isSlider) {
        $(item).closest('section').addClass('slick-initialized');

        if (setArrows) {
          $.extend(options, {
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
          });
        }
        $(item).slick(options);
      }
    });
  });
}
