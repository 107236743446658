import './scripts/foundation';
import './scripts/header';
import './scripts/tabs';
import 'slick-carousel';
import { allSliders } from './scripts/all-sliders';

const myBody = $('body');

$(document).foundation();

allSliders();

function popupTrust() {
  const section = $('.trust-section');
  if (!section.length) return;

  $(document).on('click', '.trust-section__open-popup', function (e) {
    const currentButton = $(e.currentTarget);

    currentButton.focus();

    const popupContent = currentButton
      .closest('li')
      .find('.trust-section__popup-content')
      .html();

    const html = `
				  <div class="popup trust-section__popup">
					  <div class="popup__body">
						  <div class='popup__content'>
							  <button
									  type="button"
									  aria-label="close description"
									  class='close-popup close-popup reset'
							  >
								  <span class="close-popup__icon"></span>
							  </button>
							  ${popupContent}
						  </div>
					  </div>
				  </div>
				`;

    $('footer.footer').after(html);

    const popup = $('.popup.trust-section__popup');

    setTimeout(function () {
      popup.addClass('open');
      myBody.addClass('lock');
    }, 100);

    popup.on('click', closePopupFn);
    $(document).on('keydown', onEscKeyClose);

    function closePopupFn(e) {
      if (!e.target.closest('.popup__content') || e.target.closest('.close-popup')) {
        popup.removeClass('open');
        myBody.removeClass('lock');

        popup.off('click', closePopupFn);
        $(document).off('keydown', onEscKeyClose);

        setTimeout(() => popup.remove(), 300);
      }
    }

    function onEscKeyClose(e) {
      if (e.key === 'Escape' || e.keyCode === 27) {
        closePopupFn(e);
      }
    }
  });
}

popupTrust();

$(document).on('keydown', function (e) {
  if (
    (e.metaKey || e.ctrlKey) &&
    (String.fromCharCode(e.which).toLowerCase() === 'm' ||
      String.fromCharCode(e.which).toLowerCase() === 's')
  ) {
    $('.mesh').toggleClass('hide');
  }
});
$('.footer-menu__title-toggle').on('click', function () {
  $(this)
    .toggleClass('is-active')
    .parent('.footer-menu__title-wrapper')
    .next('.footer-menu')
    .slideToggle();
  $('.footer-menu__title-toggle')
    .not(this)
    .removeClass('is-active')
    .parent('.footer-menu__title-wrapper')
    .next('.footer-menu')
    .slideUp();
});
